.container {
    display: grid;
    margin: 5%;
}
.imgForm {
    place-self: center;
    display: flex;
    align-items: center;
    justify-items: center;
    border: 1px solid black;
    flex-wrap: wrap;
}

.img img {
    height: 400px;
    width: auto;
}
.form form {
    display: flex;
    flex-direction: column;
}
.form form button {
    width: 150px;
    height: 30px;
    margin-top: 10px;
    align-self: center;
    background-color: #1C9EAF;
    color: white;
    outline: none;
    border: none;
}
form input {
    width: 200px !important;
    margin: 0 20px 2px 10px;
    background-color: rgb(229, 228, 228);
}

